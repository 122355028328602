var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-requiring-attention" },
    [
      _vm.loaded
        ? _c("online-schema-form", {
            ref: `filing-form-${_vm.currentObject.id}`,
            attrs: {
              fields: _vm.form,
              "suggestion-fields": _vm.suggestionFields,
              "save-successful": _vm.saveSuccessful,
              "contextual-jurisdiction": _vm.contextualJurisdiction,
            },
            on: {
              "show-contact-modal": _vm.showContactModal,
              navigate: _vm.navigate,
              save: _vm.saveAndNavigate,
              input: _vm.valuesUpdated,
              "suggestion-toggled": function ($event) {
                return _vm.suggestionToggled($event)
              },
              "set-current-field": _vm.asyncSetCurrentField,
              "ra-signup": _vm.raSignUp,
              "no-visible-fields": function ($event) {
                return _vm.$emit("no-visible-fields")
              },
            },
            model: {
              value: _vm.formValues,
              callback: function ($$v) {
                _vm.formValues = $$v
              },
              expression: "formValues",
            },
          })
        : _vm._e(),
      !_vm.loaded
        ? _c("ct-centered-spinner", [
            _vm._v("\n    " + _vm._s(_vm.loadingMessage) + "\n  "),
          ])
        : _vm._e(),
      _c("contact-modal", {
        ref: "contact-modal",
        attrs: { title: "Add a contact", type: "add" },
      }),
      _vm.savingData
        ? _c("ct-auto-save", { attrs: { size: "75px" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }