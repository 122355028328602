<template>
  <div class="item-requiring-attention">
    <online-schema-form
      v-if="loaded"
      :ref="`filing-form-${currentObject.id}`"
      v-model="formValues"
      :fields="form"
      :suggestion-fields="suggestionFields"
      :save-successful="saveSuccessful"
      :contextual-jurisdiction="contextualJurisdiction"
      @show-contact-modal="showContactModal"
      @navigate="navigate"
      @save="saveAndNavigate"
      @input="valuesUpdated"
      @suggestion-toggled="suggestionToggled($event)"
      @set-current-field="asyncSetCurrentField"
      @ra-signup="raSignUp"
      @no-visible-fields="$emit('no-visible-fields')"
    />

    <ct-centered-spinner v-if="!loaded">
      {{ loadingMessage }}
    </ct-centered-spinner>

    <contact-modal
      ref="contact-modal"
      :title="'Add a contact'"
      :type="'add'"
    />

    <ct-auto-save
      v-if="savingData"
      :size="'75px'"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { fillFormFromDetails, saveDetails } from '@/components/SchemaForm/helper'
import _ from 'lodash'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'StagelineItemRequiringAttention',
  components: {
    OnlineSchemaForm:     () => import('@/components/StagelineV2/schemaForm/online/OnlineSchemaForm'),
    CtCenteredSpinner:    () => import('@/components/shared/CtCenteredSpinner'),
    CtAutoSave:           () => import('@/components/shared/CtAutoSave'),
    ContactModal:         () => import('@/components/ContactModal'),
  },
  mixins: [makeToastMixin],
  props: {
    schemaLoaded: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loaded: false,
      formValues: {},
      form: {},
      suggestionFields: [],
      saveSuccessful: false,
      contextualJurisdiction: null,
      savingData: false,
      autoSaveInterval: null,
      loadingMessage: 'Loading...',
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'currentField',
      'processFilingObjects',
      'objectsRequiringAttention',
      'currentObjectId',
      'jurisdiction',
    ]),
    ...mapGetters('schema', [
      'currentObject',
      'company',
      'schema',
      'currentObjectType',
    ]),
    ...mapGetters('jurisdictions', ['findByName']),
  },

  watch: {
    formValues: {
      deep: true,
      handler(newValue) {
        this.form.values = newValue
      },
    },
  },

  async mounted() {
    await this.setForm()
    await this.autoSave()
  },

  beforeDestroy() {
    clearInterval(this.autoSaveInterval)
  },

  methods: {
    ...mapActions('schema', [
      'loadSchema',
      'loadObject',
    ]),
    ...mapActions('stageline', [
      'asyncSetCurrentField',
      'setObjectIdAndField',
      'goToNextSlide',
    ]),
    ...mapActions('checkout', [
      'addToCart',
      'fetchProduct',
    ]),

    async setForm() {
      this.loaded = false
      this.suggestionFields = []
      this.formValues = {}
      this.setJurisdiction()
      if (!this.schemaLoaded) await this.loadSchema()
      this.form = this.schema
      this.loadFormValuesAndSuggestionFields()
      this.loaded = true
    },

    setJurisdiction() {
      this.contextualJurisdiction = typeof(this.currentObject.jurisdiction) === 'object' ?
        this.currentObject.jurisdiction :
        this.findByName(this.currentObject.jurisdiction)
    },

    loadFormValuesAndSuggestionFields() {
      const details = {
        ...this.company.details,
        ...this.currentObject.data.default,
      }
      const [formValues, suggestionFields] = fillFormFromDetails(this.form, details)
      if (!_.isEmpty(formValues)) {
        this.formValues = formValues
        this.form.values = this.formValues
      }
      if (Object.keys(suggestionFields).length) this.suggestionFields = suggestionFields
    },

    async navigate(navigateTo) {
      const form = this.$refs[`filing-form-${this.currentObject.id}`].form
      const currentField = form.children.find(c => c.name === this.currentField)

      if (!currentField?.validate() && navigateTo.object !== 'previous') {
        this.errorToast('Error', 'Please fill out the rest of the required information.')
        return
      }

      if (navigateTo?.field === 'review' || navigateTo?.object !== null) {
        this.loaded = false
        const formComplete = this.formComplete(navigateTo)
        await this.save(navigateTo, formComplete)
        this.loaded = true
      }

      if (navigateTo.field !== null) {
        this.loaded = false
        await this.asyncSetCurrentField(navigateTo.field)
        this.loaded = true
      } else if (navigateTo.object !== null) {
        this.loaded = false
        await this.navigateToObject(navigateTo.object)
        this.loaded = true
      }
    },

    async autoSave() {
      this.autoSaveInterval = setInterval(() => {
        setTimeout(async () => {
          this.savingData = true
          await this.save()
        }, 16000)

        this.savingData = false
      }, 20000)
    },

    async saveAndNavigate() {
      this.loadingMessage = 'Saving...'
      this.loaded = false
      await this.save()
      await this.goToNextSlide()
      this.loaded = true
    },

    async save(formComplete = false) {
      const result = await saveDetails({
        form: this.form,
        formValues: this.formValues,
        item: this.currentObject,
        formComplete: formComplete,
      })
      if (!result[0].success) this.errorToast('Error', result[0].message)
    },

    formComplete(navigateTo) {
      return this.currentObjectType != 'order-items' && (navigateTo?.field === 'review' || navigateTo?.object === 'next')
    },

    async navigateToObject(object) {
      const currentIndex = this.objectsRequiringAttention.findIndex(o => o.object_id == this.currentObjectId)
      const nextIndex = object === 'next' ? currentIndex + 1 : currentIndex - 1
      const nextObject = this.objectsRequiringAttention[nextIndex]
      const startingField = object === 'next' ? 'first' : 'last'

      if (nextObject) await this.loadObject({ object_type: nextObject.object_type, object_id: nextObject.object_id })
      await this.setForm()
      if (nextObject) await this.setObjectIdAndField({ objectId: nextObject.object_id, startingField })
    },

    suggestionToggled(event) {
      if (event?.field?.meta?.type === 'address' && !event?.usingSuggestion) {
        delete this.suggestionFields[event.field.name]
      }
    },

    showContactModal() {
      this.$refs['contact-modal'].show()
    },

    valuesUpdated(formData) {
      this.formValues = formData
    },

    async raSignUp() {
      const jurisdictionId = this.contextualJurisdiction.state_province_region === 'Federal' ?
          this.jurisdiction.id :
          this.contextualJurisdiction.id


      let raProduct = await this.fetchProduct({
        productKind: 'registered_agent_product',
        jurisdictionId: jurisdictionId,
      })
      if (raProduct.length) {
        raProduct = raProduct[0]
        await this.addToCart( { ...raProduct, skipResolveOptionalItems: true } )
      }
    },
  },
}
</script>
